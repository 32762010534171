<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div class="wrap">
        <h1>Интерактивная карта СЗН</h1>
        <h4>Раздел</h4>
        <v-select
          v-model="selectedSection"
          dense
          outlined
          :items="filtersConfig"
          item-text="title"
          return-object
        ></v-select>

        <h4>Фильтры</h4>
        <template v-if="selectedSection == null">
          <div>Укажите раздел</div>
        </template>
        <template v-else>
          <div class="list config">
            <div class="list-item-wrap">
              <v-expansion-panels :accordion="true" :flat="true">
                <v-expansion-panel v-for="indicator in selectedSection.indicators" :key="indicator.id + filterKey">
                  <v-expansion-panel-header inside @keyup.space.prevent :hide-actions="true">
                    <div class="list-item">
                      <div class="list-item-name">
                        <button class="toggleSwitch whiteBg">
                          <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                          </svg>
                          <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                          </svg>
                        </button>
                        <v-badge
                            :value="getBadge(selectedSection.id, indicator.id)"
                            :content="getBadge(selectedSection.id, indicator.id)"
                            inline
                            color="#0033A0">
                          <span>{{ indicator.id }}. {{ indicator.title }}</span>
                        </v-badge>
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <template v-if="indicator.type === 'BY_INDICATOR_OPTIONS' || indicator.type === 'ONLY_ONE_OF_OPTIONS'">
                      <v-checkbox
                          v-for="parameter in indicator.parameters"
                          :key="indicator.title + selectedSection.id + indicator.id + parameter.id"
                          :label="getParameterName('INDICATOR_OPTION', indicator.passportConfigLink, parameter.optionId)"
                          :value="parameter.name"
                          v-model="filters"
                          @change="formIndicatorBadge()"
                      ></v-checkbox>
                    </template>

                    <template v-if="indicator.type === 'BY_SUBINDICATOR_OPTIONS'">
                      <v-checkbox
                          v-for="parameter in indicator.parameters"
                          :key="indicator.title + selectedSection.id + indicator.id + parameter.id"
                          :label="getParameterName('SUBINDICATOR_OPTION', indicator.passportConfigLink, parameter.optionId)"
                          :value="parameter.name"
                          v-model="filters"
                          @change="formIndicatorBadge()"
                      ></v-checkbox>
                    </template>

                    <template v-if="indicator.type === 'ONE_OPTION_IN_GROUP_OF_SUBINDICATORS'">
                      <v-checkbox
                          v-for="parameter in indicator.parameters"
                          :key="indicator.title + selectedSection.id + indicator.id + parameter.id"
                          :label="getParameterName('SUBINDICATOR_ONE_OPTION', indicator.passportConfigLink, parameter.optionId)"
                          :value="parameter.name"
                          v-model="filters"
                          @change="formIndicatorBadge()"
                      ></v-checkbox>
                    </template>

                    <template v-if="indicator.type === 'RANGE-[]'
                                    || indicator.type === 'RANGE-[)'
                                    || indicator.type === 'NOT_EMPTY_SUBINDICATOR'
                                    || indicator.type === 'BY_ONE_SUBINDICATOR'
                                    || indicator.type === 'BY_GROUP_OF_SUBINDICATORS'
                                    || indicator.type === 'BY_GROUP_OF_INDICATORS'
                                    || indicator.type === 'MIXED'">
                      <v-checkbox
                          v-for="parameter in indicator.parameters"
                          :key="indicator.title + selectedSection.id + indicator.id + parameter.id"
                          :label="parameter.title"
                          :value="parameter.name"
                          v-model="filters"
                          @change="formIndicatorBadge()"
                      ></v-checkbox>
                    </template>

                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </template>
<!--        {{filters}}<br>-->
        <div class="submit-button">
          <v-btn @click="getFilteredRegionsValues()" :disabled="submitButtonDisabled">Применить</v-btn>
          <v-btn @click="filters = []; formIndicatorBadge()">Очистить</v-btn>
        </div>
<!--        <br>{{filtersToQuery}}-->

        <div class="main-map">
          <div class="main-map-left">
            <div class="main-map-left-wrap" :key="'map' + counterToUpdateMap">
              <highcharts
                ref="chart"
                :constructorType="'mapChart'"
                :options="chartOptions"
                class="hc"
              ></highcharts>
            </div>
          </div>
          <div class="main-map-right">
            <v-text-field
              dense
              filled
              placeholder="Введите название региона"
              rounded
              v-model="searchStr"
              @input="updateRegionsList"
            ></v-text-field> <!-- TODO проверить работу без @input="updateRegionsList" -->
            <ul>
              <li v-for="reg in formRegionsRightList()" :key="'kgjg' + reg.code">
                <a
                    :href="`${$frontendURL}/passport/info/${passportConfig.id}/${reg.code}/${sectionIndexesForQuery.join('-')}`"
                    target='_blank'>
                  <v-tooltip top max-width="800px">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">{{ reg.name }}</div>
                    </template>
                    <span class="tooltip">
                      <table>
                        <tr v-for="(value, vIndex) in sortedValuesByRegions.get(reg.code)" :key="'v' + reg.code + value.id + vIndex">
                          <div style="display: none">{{ t = findParameterName(value)}}</div>
                          <td>{{ t.title }}: </td>
                          <td>{{ t.val ? t.val : value.value }}</td>
                        </tr>
                      </table>
                    </span>
                  </v-tooltip>
                </a>
<!--                <span class="round">{{ reg.cznCount }}</span>-->
              </li>
            </ul>
          </div>
        </div>

<!--        <br/><br/>-->
<!--        filteredRegionsValues: {{ filteredRegionsValues }}-->
<!--        <br/><br/>-->
<!--        uniqueFilteredRegionCodes: {{ uniqueFilteredRegionCodes }}-->
      </div>
    </transition>
  </div>
</template>

<script>
import api from "../modules/api";
// import rudMap from "@highcharts/map-collection/countries/ru/custom/ru-all-disputed.geo.json";
import rudMap from "../modules/customMapRu";
import {filtersConfig} from "@/modules/filterConfig";


let global_componentThis;

export default {
  name: "Home",

  data() {
    return {
      selectedSection: null,
      selectedIndicators: [],
      filters: [],
      filtersToQuery: [],
      filteredRegionsValues: [],
      uniqueFilteredRegionCodes: [],
      sectionIndexesForQuery: [],
      regionProps: new Map(),
      sortedValuesByRegions: new Map(),
      filtersConfig,
      passportConfig: null,
      searchStr: "",
      errorText: "",
      submitButtonDisabled: false,
      counterToUpdateMap: 0,
      indicatorBadge: new Map(),
      filterKey: 0,
      chartOptions: {
        chart: {
          map: rudMap,
          backgroundColor: "#F3F6FD",
        },
        title: {
          text: "",
        },
        mapNavigation: {
          enabled: true,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          pointFormatter: function () {
            return global_componentThis.pointFormatter(this["hc-key"]);
          },
        },
        series: [
          {
            name: "Регион", //highcharts-series-hover
            // color: "#f5f5f6", //6685C6
            states: {
              hover: {
                color: "#5071b6", //5071b6  e5e5e5
              },
            },
            // dataLabels: {
            //   enabled: false,
            //   format: "{point.name}",
            // },
            point: {
              events: {
                click: function () {
                  global_componentThis.openRegion(this["hc-key"]);
                },
              },
            },
            cursor: "pointer",
            allAreas: true,
            data: null,
          },
        ],
      },
    };
  },

  methods: {
    async getPassportActiveConfig() {
      let req = await api.get("/passport/getActiveConfig");
      if (req.ok) {
        this.passportConfig = req.payload;
        this.passportConfig.sections = JSON.parse(this.passportConfig.sectionsJson);
        // console.log('this.passportConfig', this.passportConfig)
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    getRegionProps() {
      for (let region of this.$regions) {
        this.regionProps.set(region.code, {
          code: region.code,
          hc_key: region.hc_key,
          name: region.name,
          // cznCount: region.cznCount //не нужно, либо нужно поменять логику и собирать реальные данные по региону
        })
      }
      // console.log("map", Object.fromEntries(this.regionProps.entries()))
    },

    getParameterName(type, passportConfigLink, id) {
      for (let section of this.passportConfig.sections) {
        if (passportConfigLink.sectionId === section.id) {
          for (let indicator of section.indicators) {
            if (passportConfigLink.indicatorId === indicator.id) {
              if (type === 'INDICATOR_OPTION') {
                for (let option of indicator.options) {
                  if (option.id === id) {
                    return option.value
                  }
                }
              } else if (type === 'SUBINDICATOR_ONE_OPTION') {
                for (let subIndicator of indicator.subIndicators) {
                  if (String(passportConfigLink.subIndicatorId[0]) === subIndicator.id) {
                    for (let option of subIndicator.options) {
                      if (option.id === id) {
                        return option.value
                      }
                    }
                  }
                }
              } else if (type === 'SUBINDICATOR_OPTION') {
                for (let subIndicator of indicator.subIndicators) {
                  if (String(passportConfigLink.subIndicatorId) === subIndicator.id) {
                    for (let option of subIndicator.options) {
                      if (option.id === id) {
                        return option.value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },

    async getFilteredRegionsValues() {
      this.submitButtonDisabled = true;
      this.filteredRegionsValues.splice(0, this.filteredRegionsValues.length);
      this.uniqueFilteredRegionCodes.splice(0, this.filteredRegionsValues.length);
      let obj = this.formFiltersToQuery(),
          req = await api.postJson("/passport/getFilteredValues", obj);
      if (req.ok) {
        this.filteredRegionsValues = req.payload;
        this.getSortedValuesByRegions();
        this.updateRegionsList();
        // console.log('this.filteredRegionsValues', this.filteredRegionsValues);
        // console.log('this.formRegionsRightList()', this.formRegionsRightList());
        this.submitButtonDisabled = false;
        // this.$forceUpdate();
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
        this.submitButtonDisabled = false;
      }
    },

    formSectionIndexesToQuery(sectionId) {
      if (!this.sectionIndexesForQuery.includes(Number(sectionId) - 1)) {
        this.sectionIndexesForQuery.push(Number(sectionId) - 1);
      }
    },

    formFiltersToQuery() {
      let map = new Map();
      this.sectionIndexesForQuery = [];
      for (let filter of this.filters) {
        let fArray = filter.split("-"),
            fParameterId = Number(fArray[2].slice(3)),
            indicator = this.getIndicatorFromFilterConfig(Number(fArray[0].slice(3)), Number(fArray[1].slice(3))),
            sectionId = indicator.passportConfigLink.sectionId,
            indicatorId = indicator.passportConfigLink.indicatorId,
            subIndicatorId = indicator.passportConfigLink.subIndicatorId,
            mapKey = indicator.name;
        this.formSectionIndexesToQuery(sectionId);

        if (indicator.type === 'BY_INDICATOR_OPTIONS' || indicator.type === 'BY_SUBINDICATOR_OPTIONS') {
          let elem = {
            type: "=",
            configId: this.passportConfig.id,
            sectionId: sectionId,
            indicatorId: indicatorId,
            subIndicatorId: String(subIndicatorId),
            value: indicator.parameters[fParameterId-1].optionId
          }
          this.putElemToMap(map, elem, mapKey);

        } else if (indicator.type === 'RANGE-[]' || indicator.type === 'RANGE-[)') {
          let type;
          if (indicator.type === 'RANGE-[]') { //левое и правое значение включены в диапазон
            type = "[]"
          } else if (indicator.type === 'RANGE-[)') { //левое - включено, правое - не включено в диапазон
            type = "[)"
          }
          let elemMin = {
            type: type,
            configId: this.passportConfig.id,
            sectionId: sectionId,
            indicatorId: indicatorId,
            subIndicatorId: String(subIndicatorId),
            begin: indicator.parameters[fParameterId-1].criterion[0], //вставляет значения диапазона из filterConfig
            end: indicator.parameters[fParameterId-1].criterion[1],
          }
          this.putElemToMap(map, elemMin, mapKey);

        } else if (indicator.type === 'NOT_EMPTY_SUBINDICATOR') {
          let elem = {
            type: "!=",
            configId: this.passportConfig.id,
            sectionId: sectionId,
            indicatorId: indicatorId,
            subIndicatorId: String(indicator.parameters[fParameterId-1].subIndicator), //вставляет значения подиндикатора из filterConfig
            value: ""
          }
          this.putElemToMap(map, elem, mapKey);

        } else if (indicator.type === 'ONLY_ONE_OF_OPTIONS') {
          let elem = {
            type: "=",
            configId: this.passportConfig.id,
            sectionId: sectionId,
            indicatorId: indicatorId,
            subIndicatorId: String(subIndicatorId),
            value: indicator.parameters[0].optionId
          }
          this.putElemToMap(map, elem, mapKey);

        } else if (indicator.type === 'BY_ONE_SUBINDICATOR') {
          let elem = {
            configId: this.passportConfig.id,
            sectionId: sectionId,
            indicatorId: indicatorId,
            subIndicatorId: String(subIndicatorId),
          };
          let param = indicator.parameters[fParameterId-1];
          if (param.type === "OPTION") {
            elem.type = "=";
            elem.value = param.optionId;
            this.putElemToMap(map, elem, mapKey);
          } else if (param.type === "NOT_EMPTY") {
            elem.type = "!=";
            elem.value = "";
            this.putElemToMap(map, elem, mapKey);
          } else if (param.type === "EMPTY") {
            elem.type = "EMPTY";
            elem.value = "";
            this.putElemToMap(map, elem, mapKey);
          }

        } else if (indicator.type === 'BY_GROUP_OF_SUBINDICATORS') {
          let elem = {
            configId: this.passportConfig.id,
            sectionId: sectionId,
            indicatorId: indicatorId,
          }
          let param = indicator.parameters[fParameterId-1];
          if (param.type === "OPTION") {
            elem.type = "=";
            elem.value = param.optionId;
            elem.subIndicatorId = String(param.subIndicatorId);
            this.putElemToMap(map, elem, mapKey);
          } else if (param.type === "NOT_EMPTY") {
            elem.type = "!=";
            elem.value = "";
            elem.subIndicatorId = String(param.subIndicatorId);
            this.putElemToMap(map, elem, mapKey);
          } else if (param.type === "EMPTY") {
            elem.type = "EMPTY";
            elem.value = "";
            elem.subIndicatorId = String(param.subIndicatorId);
            this.putElemToMap(map, elem, mapKey);
          } else if (param.type === "GROUP_NOT_EMPTY") {
            for (let subId of param.subIndicatorId) { //добавляет отдельные элементы фильтров для проверки каждого подиндикатора в списке из filterConfig
              let elemGE = {
                type: "!=",
                configId: this.passportConfig.id,
                sectionId: sectionId,
                indicatorId: indicatorId,
                subIndicatorId: String(subId),
                value: ""
              }
              this.putElemToMap(map, elemGE, mapKey);
            }
          } else if (param.type === "GROUP_EMPTY") {
            for (let subId of param.subIndicatorId) { //добавляет отдельные элементы фильтров для проверки каждого подиндикатора в списке из filterConfig
              let elemGE = {
                type: "EMPTY",
                configId: this.passportConfig.id,
                sectionId: sectionId,
                indicatorId: indicatorId,
                subIndicatorId: String(subId),
                value: ""
              }
              this.putElemToMap(map, elemGE, mapKey + subId);//добавлен subId, чтобы эти фильтры вышли в основной массив фильтров для поиска пересечения с ними
            }
          }

        } else if (indicator.type === 'BY_GROUP_OF_INDICATORS') {
          let param = indicator.parameters[fParameterId-1];
          let elem = {
            type: "=",
            configId: this.passportConfig.id,
            sectionId: sectionId,
            indicatorId: param.indicatorId,
            subIndicatorId: String(subIndicatorId),
            value: param.optionId,
          }
          this.putElemToMap(map, elem, mapKey);

        } else if (indicator.type === 'ONE_OPTION_IN_GROUP_OF_SUBINDICATORS') {
          for (let subId of subIndicatorId) { //добавляет отдельные элементы фильтров для проверки каждого подиндикатора в списке из filterConfig
            let elem = {
              type: "=",
              configId: this.passportConfig.id,
              sectionId: sectionId,
              indicatorId: indicatorId,
              subIndicatorId: String(subId),
              value: fParameterId,
            }
            this.putElemToMap(map, elem, mapKey);
          }

        } else if (indicator.type === 'MIXED') {
          let elem = {
            configId: this.passportConfig.id,
            sectionId: sectionId,
            subIndicatorId: String(subIndicatorId),
          }
          let param = indicator.parameters[fParameterId - 1];
          if (param.type === "GROUP_OF_OPTIONS") {
            for (let option of param.optionId) { //добавляет отдельные элементы фильтров для проверки каждого значения опции в списке из filterConfig
              let elemGE = {
                type: "=",
                configId: this.passportConfig.id,
                sectionId: sectionId,
                indicatorId: param.indicatorId,
                subIndicatorId: String(subIndicatorId),
                value: option
              }
              this.putElemToMap(map, elemGE, mapKey)
            }
          } else if (param.type === "NOT_EMPTY") {
            elem.type = "!=";
            elem.value = "";
            elem.indicatorId = param.indicatorId;
            this.putElemToMap(map, elem, mapKey);
          } else if (param.type === "EMPTY") {
            elem.type = "EMPTY";
            elem.value = "";
            elem.indicatorId = param.indicatorId;
            this.putElemToMap(map, elem, mapKey);
          }
        }
      }
      this.filtersToQuery = Array.from(map.values());
      return this.filtersToQuery
    },

    //Формируется мапа, из которой будет сделан массив массивов фильтров.
    //Найденные элементы по фильтрам внутренних массивов в бэке объединяются (применяется оператор "OR"),
    //далее в рамках общего массива среди всех этих найденных групп элементов находится их пересечение (оператор "AND")
    //поэтому мапа так и формируется - элементы внутри одного ключа будут объединятся, а потом будет найдено пересечение
    putElemToMap(map, elem, mapKey) {
      if (!map.has(mapKey)) {
        map.set(mapKey, []);
        map.get(mapKey).push(elem)
      } else {
        map.get(mapKey).push(elem)
      }
    },

    getIndicatorFromFilterConfig(sectionId, indicatorId) {
      for (let section of this.filtersConfig) {
        if (sectionId === section.id) {
          for (let indicator of section.indicators) {
            if (indicatorId === indicator.id) {
              return indicator
            }
          }
        }
      }
    },

    getSortedValuesByRegions() {
      this.sortedValuesByRegions.clear();
      for (let v of this.filteredRegionsValues) {
        if (!this.sortedValuesByRegions.has(v.region)) {
          this.sortedValuesByRegions.set(v.region, []);
          this.sortedValuesByRegions.get(v.region).push(v);
        } else {
          this.sortedValuesByRegions.get(v.region).push(v);
        }
      }
      this.uniqueFilteredRegionCodes = Array.from(this.sortedValuesByRegions.keys())
    },

    //составляет мапу с мапами, в которых содержится инфа по количеству выбранных фильтров
    formIndicatorBadge() {
      let count = 0;
      this.indicatorBadge.clear();
      if (this.filters.length !== 0) {
        for (let filter of this.filters) {
          let fArray = filter.split("-"),
              fSectionId = Number(fArray[0].slice(3)),
              fIndicatorId = Number(fArray[1].slice(3));
          if (!this.indicatorBadge.has(fSectionId)) {
            let indicatorMap = new Map();
            indicatorMap.set(fIndicatorId, 1);
            this.indicatorBadge.set(fSectionId, indicatorMap);
          } else {
            if (!this.indicatorBadge.get(fSectionId).has(fIndicatorId)) {
              this.indicatorBadge.get(fSectionId).set(fIndicatorId, 1);
            } else {
              let count = this.indicatorBadge.get(fSectionId).get(fIndicatorId);
              this.indicatorBadge.get(fSectionId).set(fIndicatorId, count + 1);
            }
          }
        }
      }
      this.filterKey = count;
      count += 1;
    },

    getBadge (sectionId, indicatorId) {
      if (this.indicatorBadge.has(sectionId)) {
        if (this.indicatorBadge.get(sectionId).has(indicatorId)) {
          return this.indicatorBadge.get(sectionId).get(indicatorId)
        } else {
          return null
        }
      } else {
        return null
      }
    },

    formRegionsRightList() {
      if (this.uniqueFilteredRegionCodes.length !== 0) {
        let regsList = this.uniqueFilteredRegionCodes.map((region) => this.regionProps.get(region));
        if (this.searchStr != null && this.searchStr.length > 0) {
          let upSearchStr = this.searchStr.toUpperCase();
          return regsList.filter((v) => {
            return v.name.toUpperCase().includes(upSearchStr);
          });
        } else {
          return regsList;
        }
      } else {
        return []
      }
    },

    pointFormatter(hc_key) {
      for (let r of this.$regions) {
        if (r.hc_key === hc_key)
          return r.name
      }
      return "no data";
    },

    openRegion(hc_key) {
      for (let r of this.$regions) {
        if (r.hc_key === hc_key) {
          window.open(`${this.$frontendURL}/passport/info/${this.passportConfig.id}/${r.code}/${this.sectionIndexesForQuery.join('-')}`,'_blanc');
        }
      }
    },

    filter() {
      this.updateRegionsList();
    },

    updateRegionsList() {
      if (this.uniqueFilteredRegionCodes.length !== 0) {
        let regsList = this.uniqueFilteredRegionCodes.map((region) => this.regionProps.get(region));
        // console.log('regsList', regsList)
        this.chartOptions.series[0].color = "#6685C6";
        this.chartOptions.series[0].states.hover.color = "#5071b6";
        this.chartOptions.series[0].data = regsList.map((v) => {
          return [v.hc_key, ''];
        });
      } else {
        this.chartOptions.series[0].color = "#f5f5f6";
        this.chartOptions.series[0].states.hover.color = "#e5e5e5";
        this.chartOptions.series[0].data = this.$regions.map((v) => {
          return [v.hc_key, ''];
        });
      }
      //без принудительной перерисовки карты названия регионов при наведении мышкой перепутываются
      this.counterToUpdateMap += 1;
    },

    findParameterName(value) {
      for (let section of this.passportConfig.sections) {
        if (section.id === value.sectionId) {
          for (let indicator of section.indicators) {
            if (indicator.id === value.indicatorId) {
              if (value.subIndicatorId == null) {
                if (indicator.dataType === "OPTIONAL") { //достаёт наименование опции, выбранное в паспорте
                  return {
                    title: indicator.name,
                    val: indicator.options.filter(item => item.id === Number(value.value))[0].value
                  }
                } else {
                  return {
                    title: indicator.name,
                    val: null
                  }
                }
              } else { //если сабиндикатор есть, то аналогично осуществляется поиск по нему
                for (let subIndicator of indicator.subIndicators) {
                  if (subIndicator.id === value.subIndicatorId) {
                    if (subIndicator.dataType === "OPTIONAL") {
                      return {
                        title: subIndicator.name,
                        val: subIndicator.options.filter(item => item.id === Number(value.value))[0].value
                      }
                    } else {
                      return {
                        title: subIndicator.name,
                        val: null
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },

  },

  beforeMount() {
    this.getPassportActiveConfig();
    this.getRegionProps();
    // this.updateRegionsList();
    global_componentThis = this;
    // this.loadCznData()
  },

  mounted() {
    this.updateRegionsList();
  }
};
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>


<style lang="scss" scoped>
</style>
