//type:
//BY_INDICATOR_OPTIONS - берутся все значения из опций индикатора/подинтикатора и составляется список
//BY_SUBINDICATOR_OPTIONS - берутся все значения из опций индикатора/подинтикатора и составляется список
//RANGE-[] - условие-диапазон для числовых значений (в диапазон включены левое и правое значение)
//RANGE-[) - условие-диапазон для числовых значений (в диапазоне левое - вкл., правое - не вкл.)
//ONLY_ONE_OF_OPTIONS - берется только одно значение из опций индикатора/подинтикатора
//BY_ONE_SUBINDICATOR - формирует список фильтров для значений субиндикатора (с подтипами ниже)
//--OPTION - проверяет на соответствие значения субиндикатора указанной опции
//--NOT_EMPTY - проверяет не пустое ли значение субиндикатора
//--EMPTY - проверяет пустое ли значение субиндикатора
//BY_GROUP_OF_SUBINDICATORS - формирует список фильтров для значений группы субиндикаторов (с подтипами ниже)
//--OPTION - проверяет на соответствие значения субиндикатора указанной опции
//--NOT_EMPTY - проверяет не пустое ли значение субиндикатора
//--EMPTY - проверяет пустое ли значение субиндикатора
//--GROUP_NOT_EMPTY - проверяет не пустое ли значение в группе субиндикаторов
//--GROUP_EMPTY - проверяет пустое ли значение в группе субиндикаторов
//ONE_OPTION_IN_GROUP_OF_SUBINDICATORS - проверяется одно значение из опции у группы индикаторов
//NOT_EMPTY_SUBINDICATOR - составляет список из указанных подиндикаторов, проходится по ним и проверяет не пустое ли значение
//BY_GROUP_OF_INDICATORS - формирует список фильтров для значений группы индикаторов (с подтипами ниже)
//--OPTION - проверяет на соответствие значения индикатора указанной опции
//MIXED - уникальный фильтр для индикаторов под пункт 7.1 (с подтипами ниже)
//--GROUP_OF_OPTIONS - проверяет на соответствие значения индикатора указанным опциям
//--NOT_EMPTY - проверяет не пустое ли значение индикатора
//--EMPTY - проверяет пустое ли значение индикатора

export const filtersConfig = [
    {
        id: 1,
        name: "sec1",
        title: "1. Основные параметры СЗН",
        indicators: [
            {
                id: 1,
                name: "sec1-ind1",
                title: "Модель сети",
                passportConfigLink: {
                    sectionId: 1,
                    indicatorId: 10,
                    subIndicatorId: null,
                },
                type: 'BY_INDICATOR_OPTIONS',
                parameters: [
                    {id: 1, name: "sec1-ind1-prm1", optionId: 1},
                    {id: 2, name: "sec1-ind1-prm2", optionId: 2},
                    {id: 3, name: "sec1-ind1-prm3", optionId: 3},
                ]
            },
            {
                id: 2,
                name: "sec1-ind2",
                title: "Число офисов ЦЗН",
                passportConfigLink: {
                    sectionId: 1,
                    indicatorId: 11,
                    subIndicatorId: null,
                },
                type: 'RANGE-[)',
                parameters: [
                    {id: 1, name: "sec1-ind2-prm1", title: "до 5", criterion: [0, 5]},
                    {id: 2, name: "sec1-ind2-prm2", title: "от 5 до 10", criterion: [5, 10]},
                    {id: 3, name: "sec1-ind2-prm3", title: "от 10 до 15", criterion: [10, 15]},
                    {id: 4, name: "sec1-ind2-prm4", title: "от 15 до 20", criterion: [15, 20]},
                    {id: 5, name: "sec1-ind2-prm5", title: "20 и выше", criterion: [20, 100000]},
                ]
            },
            {
                id: 3,
                name: "sec1-ind3",
                title: "Общая фактическая численность персонала ЦЗН",
                passportConfigLink: {
                    sectionId: 1,
                    indicatorId: 14,
                    subIndicatorId: null,
                },
                type: 'RANGE-[)',
                parameters: [
                    {id: 1, name: "sec1-ind3-prm1", title: "до 25", criterion: [0, 25]},
                    {id: 2, name: "sec1-ind3-prm2", title: "от 25 до 50", criterion: [25, 50]},
                    {id: 3, name: "sec1-ind3-prm3", title: "от 50 до 75", criterion: [50, 75]},
                    {id: 4, name: "sec1-ind3-prm4", title: "от 75 до 100", criterion: [75, 100]},
                    {id: 5, name: "sec1-ind3-prm5", title: "от 100 до 125", criterion: [100, 125]},
                    {id: 6, name: "sec1-ind3-prm6", title: "125 и выше", criterion: [125, 10000000]},
                ]
            },
            {
                id: 4,
                name: "sec1-ind4",
                title: "Отношение средней заработной платы сотрудников ЦЗН к средней заработной плате по экономике региона",
                passportConfigLink: {
                    sectionId: 1,
                    indicatorId: 17,
                    subIndicatorId: null,
                },
                type: 'RANGE-[)',
                parameters: [
                    {id: 1, name: "sec1-ind4-prm1", title: "до 70", criterion: [0, 70]},
                    {id: 2, name: "sec1-ind4-prm2", title: "от 70 до 80", criterion: [70, 80]},
                    {id: 3, name: "sec1-ind4-prm3", title: "от 80 до 90", criterion: [80, 90]},
                    {id: 4, name: "sec1-ind4-prm4", title: "от 90 до 100", criterion: [90, 100]},
                    {id: 5, name: "sec1-ind4-prm5", title: "100 и выше", criterion: [100, 100000]},
                ]
            },
            {
                id: 5,
                name: "sec1-ind5",
                title: "Количество сотрудников ЦЗН, которые прошли обучение в рамках федерального проекта" +
                    " «Содействие занятости» национального проекта «Демография»",
                passportConfigLink: {
                    sectionId: 1,
                    indicatorId: 21,
                    subIndicatorId: null,
                },
                type: 'RANGE-[)',
                parameters: [
                    {id: 1, name: "sec1-ind5-prm1", title: "до 25", criterion: [0, 25]},
                    {id: 2, name: "sec1-ind5-prm2", title: "от 25 до 50", criterion: [25, 50]},
                    {id: 3, name: "sec1-ind5-prm3", title: "от 50 до 75", criterion: [50, 75]},
                    {id: 4, name: "sec1-ind5-prm4", title: "от 75 до 100", criterion: [75, 100]},
                    {id: 5, name: "sec1-ind5-prm5", title: "от 100 до 125", criterion: [100, 125]},
                    {id: 6, name: "sec1-ind5-prm6", title: "125 и выше", criterion: [125, 10000000]},
                ]
            },
        ]
    },
    {
        id: 2,
        name: "sec2",
        title: "2. Модернизация отдельных ЦЗН в рамках Федерального проекта «Содействие занятости»",
        indicators: [
            {
                id: 1,
                name: "sec2-ind1",
                title: "Год начала участия в Федеральном проекте",
                passportConfigLink: {
                    sectionId: 5,
                    indicatorId: 1,
                    subIndicatorId: null,
                },
                type: 'NOT_EMPTY_SUBINDICATOR',
                parameters: [
                    {id: 1, name: "sec2-ind1-prm1", title: "2019", subIndicator: 1},
                    {id: 2, name: "sec2-ind1-prm2", title: "2020", subIndicator: 2},
                    {id: 3, name: "sec2-ind1-prm3", title: "2021", subIndicator: 3},
                    {id: 4, name: "sec2-ind1-prm4", title: "2022", subIndicator: 4},
                ]
            },
            {
                id: 2,
                name: "sec2-ind2",
                title: "Число модернизированных ЦЗН с начала участия в Федеральном проекте",
                passportConfigLink: {
                    sectionId: 5,
                    indicatorId: 9,
                    subIndicatorId: null,
                },
                type: 'RANGE-[]',
                parameters: [
                    {id: 1, name: "sec2-ind2-prm1", title: "1", criterion: [1, 1]},
                    {id: 2, name: "sec2-ind2-prm2", title: "2", criterion: [2, 2]},
                    {id: 3, name: "sec2-ind2-prm3", title: "3", criterion: [3, 3]},
                    {id: 4, name: "sec2-ind2-prm4", title: "4", criterion: [4, 4]},
                    {id: 5, name: "sec2-ind2-prm5", title: "5 и более", criterion: [5, 100000]},
                ]
            },
        ]
    },
    {
        id: 3,
        name: "sec3",
        title: "3. Комплексная модернизация региональной сети ЦЗН",
        indicators: [
            {
                id: 1,
                name: "sec3-ind1",
                title: "Регионы 1-й очереди комплексной модернизации (2023 год)",
                passportConfigLink: {
                    sectionId: 10,
                    indicatorId: 2,
                    subIndicatorId: null,
                },
                type: 'ONLY_ONE_OF_OPTIONS',
                parameters: [
                    {id: 1, name: "sec3-ind1-prm1", optionId: 1},
                ]
            },
            {
                id: 2,
                name: "sec3-ind2",
                title: "Число офисов ЦЗН, модернизируемых в рамках комплексной модернизации",
                passportConfigLink: {
                    sectionId: 10,
                    indicatorId: 3,
                    subIndicatorId: 1,
                },
                type: 'RANGE-[)',
                parameters: [
                    {id: 1, name: "sec3-ind2-prm1", title: "до 5", criterion: [0, 5]},
                    {id: 2, name: "sec3-ind2-prm2", title: "от 5 до 10", criterion: [5, 10]},
                    {id: 3, name: "sec3-ind2-prm3", title: "от 10 до 15", criterion: [10, 15]},
                    {id: 4, name: "sec3-ind2-prm4", title: "от 15 до 20", criterion: [15, 20]},
                    {id: 5, name: "sec3-ind2-prm5", title: "20 и выше", criterion: [20, 100000]},
                ]
            },
            {
                id: 3,
                name: "sec3-ind3",
                title: "Доля офисов ЦЗН, ремонт и оборудование которых в рамках комплексной модернизации завершены на текущий момент",
                passportConfigLink: {
                    sectionId: 10,
                    indicatorId: 3,
                    subIndicatorId: 3,
                },
                type: 'RANGE-[)',
                parameters: [
                    {id: 1, name: "sec3-ind3-prm1", title: "до 25%", criterion: [0, 25]},
                    {id: 2, name: "sec3-ind3-prm2", title: "25% - 50%", criterion: [25, 50]},
                    {id: 3, name: "sec3-ind3-prm3", title: "50% - 75%", criterion: [50, 75]},
                    {id: 4, name: "sec3-ind3-prm4", title: "75% - 99%", criterion: [75, 100]},
                    {id: 5, name: "sec3-ind3-prm5", title: "100%", criterion: [100, 200]},
                ]
            },
            {
                id: 4,
                name: "sec3-ind4",
                title: "Доля реализованных мероприятий регионального плана комплексной модернизации на текущий момент",
                passportConfigLink: {
                    sectionId: 10,
                    indicatorId: 4,
                    subIndicatorId: 3,
                },
                type: 'RANGE-[)',
                parameters: [
                    {id: 1, name: "sec3-ind4-prm1", title: "до 25%", criterion: [0, 25]},
                    {id: 2, name: "sec3-ind4-prm2", title: "25% - 50%", criterion: [25, 50]},
                    {id: 3, name: "sec3-ind4-prm3", title: "50% - 75%", criterion: [50, 75]},
                    {id: 4, name: "sec3-ind4-prm4", title: "75% - 99%", criterion: [75, 100]},
                    {id: 5, name: "sec3-ind4-prm5", title: "100%", criterion: [100, 200]},
                ]
            },
        ]
    },
    {
        id: 4,
        name: "sec4",
        title: "4. Проекты по апробация новых механизмов вместе с Федеральным центром компетенций в сфере занятости",
        indicators: [
            {
                id: 1,
                name: "sec4-ind1",
                type: 'BY_ONE_SUBINDICATOR',
                title: "Профилирование граждан и работодателей в целях оказания индивидуальных услуг и сервисов",
                passportConfigLink: {
                    sectionId: 5,
                    indicatorId: 11,
                    subIndicatorId: 1,
                },
                parameters: [
                    {id: 1, name: "sec4-ind1-prm1", type: "OPTION", title: "Участвовал", optionId: 1},
                    {id: 2, name: "sec4-ind1-prm2", type: "OPTION", title: "Не участвовал", optionId: 2},
                ]
            },
            {
                id: 2,
                name: "sec4-ind2",
                type: 'BY_ONE_SUBINDICATOR',
                title: "Сервисы для граждан",
                passportConfigLink: {
                    sectionId: 5,
                    indicatorId: 11,
                    subIndicatorId: 2,
                },
                parameters: [
                    {id: 1, name: "sec4-ind2-prm1", type: "OPTION", title: "Участвовал", optionId: 1},
                    {id: 2, name: "sec4-ind2-prm2", type: "OPTION", title: "Не участвовал", optionId: 2},
                ]
            },
            {
                id: 3,
                name: "sec4-ind3",
                type: 'BY_ONE_SUBINDICATOR',
                title: "Сервисы для работодателей",
                passportConfigLink: {
                    sectionId: 5,
                    indicatorId: 11,
                    subIndicatorId: 3,
                },
                parameters: [
                    {id: 1, name: "sec4-ind3-prm1", type: "OPTION", title: "Участвовал", optionId: 1},
                    {id: 2, name: "sec4-ind3-prm2", type: "OPTION", title: "Не участвовал", optionId: 2},
                ]
            },
            {
                id: 4,
                name: "sec4-ind4",
                type: 'BY_ONE_SUBINDICATOR',
                title: "Целевая организационная модель СЗН региона",
                passportConfigLink: {
                    sectionId: 5,
                    indicatorId: 11,
                    subIndicatorId: 4,
                },
                parameters: [
                    {id: 1, name: "sec4-ind4-prm1", type: "OPTION", title: "Участвовал", optionId: 1},
                    {id: 2, name: "sec4-ind4-prm2", type: "OPTION", title: "Не участвовал", optionId: 2},
                ]
            },
            {
                id: 5,
                name: "sec4-ind5",
                type: 'BY_ONE_SUBINDICATOR',
                title: "Организация работы с персоналом СЗН",
                passportConfigLink: {
                    sectionId: 5,
                    indicatorId: 11,
                    subIndicatorId: 5,
                },
                parameters: [
                    {id: 1, name: "sec4-ind5-prm1", type: "OPTION", title: "Участвовал", optionId: 1},
                    {id: 2, name: "sec4-ind5-prm2", type: "OPTION", title: "Не участвовал", optionId: 2},
                ]
            },
            {
                id: 6,
                name: "sec4-ind6",
                type: 'BY_ONE_SUBINDICATOR',
                title: "Система информирования граждан о деятельности СЗН",
                passportConfigLink: {
                    sectionId: 5,
                    indicatorId: 11,
                    subIndicatorId: 6,
                },
                parameters: [
                    {id: 1, name: "sec4-ind6-prm1", type: "OPTION", title: "Участвовал", optionId: 1},
                    {id: 2, name: "sec4-ind6-prm2", type: "OPTION", title: "Не участвовал", optionId: 2},
                ]
            },
            {
                id: 7,
                name: "sec4-ind7",
                type: 'BY_ONE_SUBINDICATOR',
                title: "Система управления клиентским опытом",
                passportConfigLink: {
                    sectionId: 5,
                    indicatorId: 11,
                    subIndicatorId: 7,
                },
                parameters: [
                    {id: 1, name: "sec4-ind7-prm1", type: "OPTION", title: "Участвовал", optionId: 1},
                    {id: 2, name: "sec4-ind7-prm2", type: "OPTION", title: "Не участвовал", optionId: 2},
                ]
            },
            {
                id: 8,
                name: "sec4-ind8",
                type: 'BY_ONE_SUBINDICATOR',
                title: "Показатели клиентоцентричности СЗН",
                passportConfigLink: {
                    sectionId: 5,
                    indicatorId: 11,
                    subIndicatorId: 8,
                },
                parameters: [
                    {id: 1, name: "sec4-ind8-prm1", type: "OPTION", title: "Участвовал", optionId: 1},
                    {id: 2, name: "sec4-ind8-prm2", type: "OPTION", title: "Не участвовал", optionId: 2},
                ]
            },
            {
                id: 9,
                name: "sec4-ind9",
                type: 'BY_ONE_SUBINDICATOR',
                title: "Оценка эффективности и результативности деятельности СЗН (Участвовал)",
                passportConfigLink: {
                    sectionId: 5,
                    indicatorId: 11,
                    subIndicatorId: 9,
                },
                parameters: [
                    {id: 1, name: "sec4-ind9-prm1", type: "OPTION", title: "Участвовал", optionId: 1},
                    {id: 2, name: "sec4-ind9-prm2", type: "OPTION", title: "Не участвовал", optionId: 2},
                ]
            },
            {
                id: 10,
                name: "sec4-ind10",
                type: 'BY_ONE_SUBINDICATOR',
                title: "Иные направления",
                passportConfigLink: {
                    sectionId: 5,
                    indicatorId: 11,
                    subIndicatorId: 10,
                },
                parameters: [
                    {id: 1, name: "sec4-ind10-prm1", type: "NOT_EMPTY", title: "Участвовал"},
                    {id: 2, name: "sec4-ind10-prm2", type: "EMPTY", title: "Не участвовал"},
                ]
            }
        ]
    },
    {
        id: 5,
        name: "sec5",
        title: "5. Проекты по развитию практик содействия занятости в регионах",
        indicators: [
            {
                id: 1,
                name: "sec5-ind1",
                title: "Общее количество проектов в области содействия занятости в регионе",
                passportConfigLink: {
                    sectionId: 11,
                    indicatorId: 1,
                    subIndicatorId: null,
                },
                type: 'RANGE-[)',
                parameters: [
                    {id: 1, name: "sec5-ind1-prm1", title: "1", criterion: [1, 1]},
                    {id: 2, name: "sec5-ind1-prm2", title: "2", criterion: [2, 2]},
                    {id: 3, name: "sec5-ind1-prm3", title: "3", criterion: [3, 3]},
                    {id: 4, name: "sec5-ind1-prm4", title: "4", criterion: [4, 4]},
                    {id: 5, name: "sec5-ind1-prm5", title: "5 и более", criterion: [5, 100000]},
                ]
            },
            {
                id: 2,
                name: "sec5-ind2",
                type: 'BY_GROUP_OF_SUBINDICATORS',
                title: "Организация, содействующая проекту",
                passportConfigLink: {
                    sectionId: 11,
                    indicatorId: 2,
                },
                parameters: [
                    {id: 1,  name: "sec5-ind2-prm1",  subIndicatorId: 1, title: "РАНХиГС (проект в рамках обучения) (Есть)", type: "OPTION", optionId: 1},
                    {id: 2,  name: "sec5-ind2-prm2",  subIndicatorId: 1, title: "РАНХиГС (проект в рамках обучения) (Нет)", type: "OPTION", optionId: 2},
                    {id: 3,  name: "sec5-ind2-prm3",  subIndicatorId: 2, title: "АСИ (Есть)", type: "OPTION", optionId: 1},
                    {id: 4,  name: "sec5-ind2-prm4",  subIndicatorId: 2, title: "АСИ (Нет)", type: "OPTION", optionId: 2},
                    {id: 5,  name: "sec5-ind2-prm5",  subIndicatorId: 3, title: "ВЭБ (Есть)", type: "OPTION", optionId: 1},
                    {id: 6,  name: "sec5-ind2-prm6",  subIndicatorId: 3, title: "ВЭБ (Нет)", type: "OPTION", optionId: 2},
                    {id: 7,  name: "sec5-ind2-prm7",  subIndicatorId: 4, title: "Проект «Зарабатываем вместе» (Минтруд России, АСИ, Агентство развития профессионального мастерства) (Есть)", type: "OPTION", optionId: 1},
                    {id: 8,  name: "sec5-ind2-prm8",  subIndicatorId: 4, title: "Проект «Зарабатываем вместе» (Минтруд России, АСИ, Агентство развития профессионального мастерства) (Нет)", type: "OPTION", optionId: 2},
                    {id: 9,  name: "sec5-ind2-prm9",  subIndicatorId: 5, title: "Проект «Моя карьера с Единой Россией» (Минтруд России и Партия «Единая Россия») (Есть)", type: "OPTION", optionId: 1},
                    {id: 10, name: "sec5-ind2-prm10", subIndicatorId: 5, title: "Проект «Моя карьера с Единой Россией» (Минтруд России и Партия «Единая Россия») (Нет)", type: "OPTION", optionId: 2},
                    {id: 11, name: "sec5-ind2-prm11", subIndicatorId: 6, title: "Иные (Есть)", type: "NOT_EMPTY"},
                    {id: 12, name: "sec5-ind2-prm12", subIndicatorId: 6, title: "Иные (Нет)", type: "EMPTY"},
                ]
            },
            {
                id: 3,
                name: "sec5-ind3",
                type: 'ONE_OPTION_IN_GROUP_OF_SUBINDICATORS',
                title: "Целевая аудитория",
                passportConfigLink: {
                    sectionId: 11,
                    indicatorId: 2,
                    subIndicatorId: [1.5, 2.5, 3.5, 4.5, 5.5, 6.5],
                },
                parameters: [
                    {id: 1, name: "sec5-ind3-prm1", optionId: 1},
                    {id: 2, name: "sec5-ind3-prm2", optionId: 2},
                    {id: 3, name: "sec5-ind3-prm3", optionId: 3},
                    {id: 4, name: "sec5-ind3-prm4", optionId: 4},
                ]
            },
            {
                id: 4,
                name: "sec5-ind4",
                type: 'ONE_OPTION_IN_GROUP_OF_SUBINDICATORS',
                title: "Статус проекта",
                passportConfigLink: {
                    sectionId: 11,
                    indicatorId: 2,
                    subIndicatorId: [1.7, 2.7, 3.7, 4.7, 5.7, 6.7],
                },
                parameters: [
                    {id: 1, name: "sec5-ind4-prm1", optionId: 1},
                    {id: 2, name: "sec5-ind4-prm2", optionId: 2},
                    {id: 3, name: "sec5-ind4-prm3", optionId: 3},
                ]
            }
        ]
    },
    {
        id: 6,
        name: "sec6",
        title: "6. Комплексы услуг по принципам жизненных и бизнес-ситуаций",
        indicators: [
            {
                id: 1,
                name: "sec6-ind1",
                type: 'BY_GROUP_OF_SUBINDICATORS',
                title: "Комплексы услуг и сервисов для граждан по принципам жизненным ситуаций," +
                    " внедренных в деятельность одного или более ЦЗН",
                passportConfigLink: {
                    sectionId: 6,
                    indicatorId: 3,
                },
                parameters: [
                    {id: 1,  name: "sec6-ind1-prm1",  subIndicatorId: 1, title: "Женщины с ребенком в возрасте до 3 лет (Есть)", type: "OPTION", optionId: 1},
                    {id: 2,  name: "sec6-ind1-prm2",  subIndicatorId: 1, title: "Женщины с ребенком в возрасте до 3 лет (Нет)", type: "OPTION", optionId: 2},
                    {id: 3,  name: "sec6-ind1-prm3",  subIndicatorId: 2, title: "Женщины, имеющие детей дошкольного возраста (Есть)", type: "OPTION", optionId: 1},
                    {id: 4,  name: "sec6-ind1-prm4",  subIndicatorId: 2, title: "Женщины, имеющие детей дошкольного возраста (Нет)", type: "OPTION", optionId: 2},
                    {id: 5,  name: "sec6-ind1-prm5",  subIndicatorId: 3, title: "Пенсионеры (Есть)", type: "OPTION", optionId: 1},
                    {id: 6,  name: "sec6-ind1-prm6",  subIndicatorId: 3, title: "Пенсионеры (Нет)", type: "OPTION", optionId: 2},
                    {id: 7,  name: "sec6-ind1-prm7",  subIndicatorId: 4, title: "Предпенсионеры (Есть)", type: "OPTION", optionId: 1},
                    {id: 8,  name: "sec6-ind1-prm8",  subIndicatorId: 4, title: "Предпенсионеры (Нет)", type: "OPTION", optionId: 2},
                    {id: 9,  name: "sec6-ind1-prm9",  subIndicatorId: 5, title: "Люди с инвалидностью (Есть)", type: "OPTION", optionId: 1},
                    {id: 10, name: "sec6-ind1-prm10", subIndicatorId: 5, title: "Люди с инвалидностью (Нет)", type: "OPTION", optionId: 2},
                    {id: 11, name: "sec6-ind1-prm11", subIndicatorId: 6, title: "Молодые специалисты (Есть)", type: "OPTION", optionId: 1},
                    {id: 12, name: "sec6-ind1-prm12", subIndicatorId: 6, title: "Молодые специалисты (Нет)", type: "OPTION", optionId: 2},
                    {id: 13, name: "sec6-ind1-prm13", subIndicatorId: 7, title: "Лица, отбывшие наказание (Есть)", type: "OPTION", optionId: 1},
                    {id: 14, name: "sec6-ind1-prm14", subIndicatorId: 7, title: "Лица, отбывшие наказание (Нет)", type: "OPTION", optionId: 2},
                    {id: 15, name: "sec6-ind1-prm15", subIndicatorId: 8, title: "Открытие собственного дела (Есть)", type: "OPTION", optionId: 1},
                    {id: 16, name: "sec6-ind1-prm16", subIndicatorId: 8, title: "Открытие собственного дела (Нет)", type: "OPTION", optionId: 2},
                    {id: 17, name: "sec6-ind1-prm17", subIndicatorId: 9, title: "Граждане, возобновляющие трудовую деятельность после длительного перерыва (Есть)", type: "OPTION", optionId: 1},
                    {id: 18, name: "sec6-ind1-prm18", subIndicatorId: 9, title: "Граждане, возобновляющие трудовую деятельность после длительного перерыва (Нет)", type: "OPTION", optionId: 2},
                    {id: 19, name: "sec6-ind1-prm19", subIndicatorId: 10, title: "Сироты (Есть)", type: "OPTION", optionId: 1},
                    {id: 20, name: "sec6-ind1-prm20", subIndicatorId: 10, title: "Сироты (Нет)", type: "OPTION", optionId: 2},
                    {id: 21, name: "sec6-ind1-prm21", subIndicatorId: [11, 12, 13, 14, 15], title: "Иные жизненные ситуации (Есть)", type: "GROUP_NOT_EMPTY"},
                    {id: 22, name: "sec6-ind1-prm22", subIndicatorId: [11, 12, 13, 14, 15], title: "Иные жизненные ситуации (Нет)", type: "GROUP_EMPTY"},
                ]
            },
            {
                id: 2,
                name: "sec6-ind2",
                type: 'BY_GROUP_OF_SUBINDICATORS',
                title: "Комплексы услуг и сервисов для работодателей по принципам бизнес-ситуаций," +
                    " внедренных в деятельность одного или более ЦЗН",
                passportConfigLink: {
                    sectionId: 6,
                    indicatorId: 4,
                },
                parameters: [
                    {id: 1,  name: "sec6-ind2-prm1",  subIndicatorId: 1, title: "Инвестиционный проект (Есть)", type: "OPTION", optionId: 1},
                    {id: 2,  name: "sec6-ind2-prm2",  subIndicatorId: 1, title: "Инвестиционный проект (Нет)", type: "OPTION", optionId: 2},
                    {id: 3,  name: "sec6-ind2-prm3",  subIndicatorId: 2, title: "Кадровое обеспечение сезонной потребности работодателей в персонале (Есть)", type: "OPTION", optionId: 1},
                    {id: 4,  name: "sec6-ind2-prm4",  subIndicatorId: 2, title: "Кадровое обеспечение сезонной потребности работодателей в персонале (Нет)", type: "OPTION", optionId: 2},
                    {id: 5,  name: "sec6-ind2-prm5",  subIndicatorId: 3, title: "Взаимодействие службы занятости с предприятиями при высокой текучести кадров (Есть)", type: "OPTION", optionId: 1},
                    {id: 6,  name: "sec6-ind2-prm6",  subIndicatorId: 3, title: "Взаимодействие службы занятости с предприятиями при высокой текучести кадров (Нет)", type: "OPTION", optionId: 2},
                    {id: 7,  name: "sec6-ind2-prm7",  subIndicatorId: 4, title: "Модернизация бизнеса (Есть)", type: "OPTION", optionId: 1},
                    {id: 8,  name: "sec6-ind2-prm8",  subIndicatorId: 4, title: "Модернизация бизнеса (Нет)", type: "OPTION", optionId: 2},
                    {id: 9,  name: "sec6-ind2-prm9",  subIndicatorId: 5, title: "Создание малого предприятия (Есть)", type: "OPTION", optionId: 1},
                    {id: 10, name: "sec6-ind2-prm10", subIndicatorId: 5, title: "Создание малого предприятия (Нет)", type: "OPTION", optionId: 2},
                    {id: 11, name: "sec6-ind2-prm11", subIndicatorId: 6, title: "Аутплейсмент (высвобождение) работников (Есть)", type: "OPTION", optionId: 1},
                    {id: 12, name: "sec6-ind2-prm12", subIndicatorId: 6, title: "Аутплейсмент (высвобождение) работников (Нет)", type: "OPTION", optionId: 2},
                    {id: 13, name: "sec6-ind2-prm13", subIndicatorId: 7, title: "Подбор работников в условиях дефицита кадров (Есть)", type: "OPTION", optionId: 1},
                    {id: 14, name: "sec6-ind2-prm14", subIndicatorId: 7, title: "Подбор работников в условиях дефицита кадров (Нет)", type: "OPTION", optionId: 2},
                    {id: 15, name: "sec6-ind2-prm15", subIndicatorId: 8, title: "Формирование HR-бренда и продвижение вакансий (Есть)", type: "OPTION", optionId: 1},
                    {id: 16, name: "sec6-ind2-prm16", subIndicatorId: 8, title: "Формирование HR-бренда и продвижение вакансий (Нет)", type: "OPTION", optionId: 2},
                    {id: 17, name: "sec6-ind2-prm17", subIndicatorId: 9, title: "Отложенная потребность в работниках (Есть)", type: "OPTION", optionId: 1},
                    {id: 18, name: "sec6-ind2-prm18", subIndicatorId: 9, title: "Отложенная потребность в работниках (Нет)", type: "OPTION", optionId: 2},
                    {id: 19, name: "sec6-ind2-prm19", subIndicatorId: 10, title: "Подбор работников на квотируемые рабочие места для инвалидов (Есть)", type: "OPTION", optionId: 1},
                    {id: 20, name: "sec6-ind2-prm20", subIndicatorId: 10, title: "Подбор работников на квотируемые рабочие места для инвалидов (Нет)", type: "OPTION", optionId: 2},
                    {id: 21, name: "sec6-ind2-prm21", subIndicatorId: [11, 12, 13, 14, 15], title: "Иные бизнес-ситуации (Есть)", type: "GROUP_NOT_EMPTY"},
                    {id: 22, name: "sec6-ind2-prm22", subIndicatorId: [11, 12, 13, 14, 15], title: "Иные бизнес-ситуации (Нет)", type: "GROUP_EMPTY"},
                ]
            },
            {
                id: 3,
                name: "sec6-ind3",
                type: 'RANGE-[)',
                title: "Доля офисов ЦЗН, в которых внедрено оказание комплексов услуг" +
                    " и сервисов для граждан по принципам жизненных ситуаций",
                passportConfigLink: {
                    sectionId: 6,
                    indicatorId: 6,
                    subIndicatorId: 1,
                },
                parameters: [
                    {id: 1, name: "sec6-ind3-prm1", title: "до 10%", criterion: [0, 10]},
                    {id: 2, name: "sec6-ind3-prm2", title: "10% - 30%", criterion: [10, 30]},
                    {id: 3, name: "sec6-ind3-prm3", title: "30% - 60%", criterion: [30, 60]},
                    {id: 4, name: "sec6-ind3-prm4", title: "60% - 90%", criterion: [60, 90]},
                    {id: 5, name: "sec6-ind3-prm5", title: "90% - 100%", criterion: [90, 200]},
                ]
            },
            {
                id: 4,
                name: "sec6-ind4",
                type: 'RANGE-[)',
                title: "Доля офисов ЦЗН, в которых внедрено оказание комплексов услуг" +
                    " и сервисов для работодателей по принципам бизнес-ситуаций",
                passportConfigLink: {
                    sectionId: 6,
                    indicatorId: 9,
                    subIndicatorId: 1,
                },
                parameters: [
                    {id: 1, name: "sec6-ind4-prm1", title: "до 10%", criterion: [0, 10]},
                    {id: 2, name: "sec6-ind4-prm2", title: "10% - 30%", criterion: [10, 30]},
                    {id: 3, name: "sec6-ind4-prm3", title: "30% - 60%", criterion: [30, 60]},
                    {id: 4, name: "sec6-ind4-prm4", title: "60% - 90%", criterion: [60, 90]},
                    {id: 5, name: "sec6-ind4-prm5", title: "90% - 100%", criterion: [90, 200]},
                ]
            }
        ]
    },
    {
        id: 7,
        name: "sec7",
        title: "7. Предоставление услуг и сервисов по механизму «одного окна»",
        indicators: [
            {
                id: 1,
                name: "sec7-ind1",
                type: 'MIXED',
                title: "Организация предоставления услуг и сервисов по механизму «одного окна»",
                passportConfigLink: {
                    sectionId: 6,
                    subIndicatorId: null,
                },
                parameters: [
                    {id: 1, name: "sec7-ind1-prm1", indicatorId: 11, type: "GROUP_OF_OPTIONS", optionId: [1, 2, 3], title: "Заключены соглашения о размещении удаленных рабочих мест ЦЗН в МФЦ/центрах «Мой бизнес»"},
                    {id: 2, name: "sec7-ind1-prm2", indicatorId: 12, type: "GROUP_OF_OPTIONS", optionId: [1, 2, 3], title: "Заключены соглашения о размещении удаленных рабочих мест МФЦ/центров «Мой бизнес» в ЦЗН"},
                    {id: 3, name: "sec7-ind1-prm3", indicatorId: 13, type: "NOT_EMPTY", title: "Есть отдельные офисы ЦЗН, размещеные в помещениях МФЦ"},
                    {id: 4, name: "sec7-ind1-prm4", indicatorId: 13, type: "EMPTY", title: "Нет отдельных офисов ЦЗН, размещеных в помещениях МФЦ"},
                    {id: 5, name: "sec7-ind1-prm5", indicatorId: 14, type: "NOT_EMPTY", title: "Есть отдельные офисы ЦЗН, размещеные в помещениях центров «Мой бизнес»"},
                    {id: 6, name: "sec7-ind1-prm6", indicatorId: 14, type: "EMPTY", title: "Нет отдельных офисов ЦЗН, размещеных в помещениях центров «Мой бизнес»"},
                    {id: 7, name: "sec7-ind1-prm7", indicatorId: 11, type: "GROUP_OF_OPTIONS", optionId: [4], title: "Не заключены соглашения, отсутствует размещение ЦЗН в МФЦ/центрах «Мой бизнес»"},
                    {id: 8, name: "sec7-ind1-prm8", indicatorId: 12, type: "GROUP_OF_OPTIONS", optionId: [4], title: "Не заключены соглашения, отсутствует размещение МФЦ/центров «Мой бизнес» в ЦЗН"},
                ]
            },
            {
                id: 2,
                name: "sec7-ind2",
                type: 'RANGE-[)',
                title: "Доля офисов ЦЗН, в которых размещены удаленные рабочие места МФЦ",
                passportConfigLink: {
                    sectionId: 6,
                    indicatorId: 15,
                    subIndicatorId: 1,
                },
                parameters: [
                    {id: 1, name: "sec7-ind2-prm1", title: "до 10%", criterion: [0, 10]},
                    {id: 2, name: "sec7-ind2-prm2", title: "10% - 30%", criterion: [10, 30]},
                    {id: 3, name: "sec7-ind2-prm3", title: "30% - 60%", criterion: [30, 60]},
                    {id: 4, name: "sec7-ind2-prm4", title: "60% - 90%", criterion: [60, 90]},
                    {id: 5, name: "sec7-ind2-prm5", title: "90% - 100%", criterion: [90, 200]},
                ]
            },
            {
                id: 3,
                name: "sec7-ind3",
                type: 'RANGE-[)',
                title: "Доля офисов ЦЗН, в которых размещены удаленные рабочие места центров «Мой бизнес»",
                passportConfigLink: {
                    sectionId: 6,
                    indicatorId: 16,
                    subIndicatorId: 1,
                },
                parameters: [
                    {id: 1, name: "sec7-ind3-prm1", title: "до 10%", criterion: [0, 10]},
                    {id: 2, name: "sec7-ind3-prm2", title: "10% - 30%", criterion: [10, 30]},
                    {id: 3, name: "sec7-ind3-prm3", title: "30% - 60%", criterion: [30, 60]},
                    {id: 4, name: "sec7-ind3-prm4", title: "60% - 90%", criterion: [60, 90]},
                    {id: 5, name: "sec7-ind3-prm5", title: "90% - 100%", criterion: [90, 200]},
                ]
            },
            {
                id: 4,
                name: "sec7-ind4",
                type: 'RANGE-[)',
                title: "Доля офисов ЦЗН, размещенных в помещениях МФЦ",
                passportConfigLink: {
                    sectionId: 6,
                    indicatorId: 13,
                    subIndicatorId: 1,
                },
                parameters: [
                    {id: 1, name: "sec7-ind4-prm1", title: "до 10%", criterion: [0, 10]},
                    {id: 2, name: "sec7-ind4-prm2", title: "10% - 30%", criterion: [10, 30]},
                    {id: 3, name: "sec7-ind4-prm3", title: "30% - 60%", criterion: [30, 60]},
                    {id: 4, name: "sec7-ind4-prm4", title: "60% - 90%", criterion: [60, 90]},
                    {id: 5, name: "sec7-ind4-prm5", title: "90% - 100%", criterion: [90, 200]},
                ]
            },
            {
                id: 5,
                name: "sec7-ind5",
                type: 'RANGE-[)',
                title: "Доля офисов ЦЗН, размещенных в помещениях центров «Мой бизнес»",
                passportConfigLink: {
                    sectionId: 6,
                    indicatorId: 14,
                    subIndicatorId: 1,
                },
                parameters: [
                    {id: 1, name: "sec7-ind5-prm1", title: "до 10%", criterion: [0, 10]},
                    {id: 2, name: "sec7-ind5-prm2", title: "10% - 30%", criterion: [10, 30]},
                    {id: 3, name: "sec7-ind5-prm3", title: "30% - 60%", criterion: [30, 60]},
                    {id: 4, name: "sec7-ind5-prm4", title: "60% - 90%", criterion: [60, 90]},
                    {id: 5, name: "sec7-ind5-prm5", title: "90% - 100%", criterion: [90, 200]},
                ]
            }
        ]
    },
    {
        id: 8,
        name: "sec8",
        title: "8. Участие во Всероссийском конкурсе профессионального мастерства в сфере  содействия занятости населения в 2022 году",
        indicators: [
            {
                id: 1,
                name: "sec8-ind1",
                type: 'BY_GROUP_OF_INDICATORS',
                title: "Участие во Всероссийском конкурсе (по номинациям)",
                passportConfigLink: {
                    sectionId: 12,
                    subIndicatorId: null,
                },
                parameters: [
                    {id: 1,  name: "sec8-ind1-prm1",  indicatorId: 2, optionId: 1, title: "«Лучший специалист» - категория «Кадровый консультант» (Участвовал)"},
                    {id: 2,  name: "sec8-ind1-prm2",  indicatorId: 2, optionId: 2, title: "«Лучший специалист» - категория «Кадровый консультант» (Не участвовал)"},
                    {id: 3,  name: "sec8-ind1-prm3",  indicatorId: 3, optionId: 1, title: "«Лучший специалист» - категория «Карьерный консультант» (Участвовал)"},
                    {id: 4,  name: "sec8-ind1-prm4",  indicatorId: 3, optionId: 2, title: "«Лучший специалист» - категория «Карьерный консультант» (Не участвовал)"},
                    {id: 5,  name: "sec8-ind1-prm5",  indicatorId: 4, optionId: 1, title: "«Лучший ЦЗН» - категория «Крупный ЦЗН» (Участвовал)"},
                    {id: 6,  name: "sec8-ind1-prm6",  indicatorId: 4, optionId: 2, title: "«Лучший ЦЗН» - категория «Крупный ЦЗН» (Не участвовал)"},
                    {id: 7,  name: "sec8-ind1-prm7",  indicatorId: 5, optionId: 1, title: "«Лучший ЦЗН» - категория «Средний ЦЗН» (Участвовал)"},
                    {id: 8,  name: "sec8-ind1-prm8",  indicatorId: 5, optionId: 2, title: "«Лучший ЦЗН» - категория «Средний ЦЗН» (Не участвовал)"},
                    {id: 9,  name: "sec8-ind1-prm9",  indicatorId: 6, optionId: 1, title: "«Лучший ЦЗН» - категория «Малый ЦЗН» (Участвовал)"},
                    {id: 10, name: "sec8-ind1-prm10", indicatorId: 6, optionId: 2, title: "«Лучший ЦЗН» - категория «Малый ЦЗН» (Не участвовал)"},
                    {id: 11, name: "sec8-ind1-prm11", indicatorId: 7, optionId: 1, title: "«Лучший проект» (Участвовал)"},
                    {id: 12, name: "sec8-ind1-prm12", indicatorId: 7, optionId: 2, title: "«Лучший проект» (Не участвовал)"},
                ]
            },
            {
                id: 2,
                name: "sec8-ind2",
                title: "Результаты участия в финале федерального этапа в номинации «Лучший специалист» - категория «Кадровый консультант»",
                passportConfigLink: {
                    sectionId: 12,
                    indicatorId: 2,
                    subIndicatorId: 3,
                },
                type: 'BY_SUBINDICATOR_OPTIONS',
                parameters: [
                    {id: 1, name: "sec8-ind2-prm1", optionId: 1},
                    {id: 2, name: "sec8-ind2-prm2", optionId: 2},
                    {id: 3, name: "sec8-ind2-prm3", optionId: 3},
                    {id: 4, name: "sec8-ind2-prm4", optionId: 4},
                ]
            },
            {
                id: 3,
                name: "sec8-ind3",
                title: "Результаты участия в финале федерального этапа в номинации «Лучший специалист» - категория «Карьерный консультант»",
                passportConfigLink: {
                    sectionId: 12,
                    indicatorId: 3,
                    subIndicatorId: 3,
                },
                type: 'BY_SUBINDICATOR_OPTIONS',
                parameters: [
                    {id: 1, name: "sec8-ind3-prm1", optionId: 1},
                    {id: 2, name: "sec8-ind3-prm2", optionId: 2},
                    {id: 3, name: "sec8-ind3-prm3", optionId: 3},
                    {id: 4, name: "sec8-ind3-prm4", optionId: 4},
                ]
            },
            {
                id: 4,
                name: "sec8-ind4",
                title: "Результаты участия в финале федерального этапа в номинации «Лучший ЦЗН» - категория «Крупный ЦЗН»",
                passportConfigLink: {
                    sectionId: 12,
                    indicatorId: 4,
                    subIndicatorId: 3,
                },
                type: 'BY_SUBINDICATOR_OPTIONS',
                parameters: [
                    {id: 1, name: "sec8-ind4-prm1", optionId: 1},
                    {id: 2, name: "sec8-ind4-prm2", optionId: 2},
                    {id: 3, name: "sec8-ind4-prm3", optionId: 3},
                    {id: 4, name: "sec8-ind4-prm4", optionId: 4},
                ]
            },
            {
                id: 5,
                name: "sec8-ind5",
                title: "Результаты участия в финале федерального этапа в номинации «Лучший ЦЗН» - категория «Средний ЦЗН»",
                passportConfigLink: {
                    sectionId: 12,
                    indicatorId: 5,
                    subIndicatorId: 3,
                },
                type: 'BY_SUBINDICATOR_OPTIONS',
                parameters: [
                    {id: 1, name: "sec8-ind5-prm1", optionId: 1},
                    {id: 2, name: "sec8-ind5-prm2", optionId: 2},
                    {id: 3, name: "sec8-ind5-prm3", optionId: 3},
                    {id: 4, name: "sec8-ind5-prm4", optionId: 4},
                ]
            },
            {
                id: 6,
                name: "sec8-ind6",
                title: "Результаты участия в финале федерального этапа в номинации «Лучший ЦЗН» - категория «Малый ЦЗН»",
                passportConfigLink: {
                    sectionId: 12,
                    indicatorId: 6,
                    subIndicatorId: 3,
                },
                type: 'BY_SUBINDICATOR_OPTIONS',
                parameters: [
                    {id: 1, name: "sec8-ind6-prm1", optionId: 1},
                    {id: 2, name: "sec8-ind6-prm2", optionId: 2},
                    {id: 3, name: "sec8-ind6-prm3", optionId: 3},
                    {id: 4, name: "sec8-ind6-prm4", optionId: 4},
                ]
            },
            {
                id: 7,
                name: "sec8-ind7",
                title: "Результаты участия в финале федерального этапа в номинации «Лучший проект»",
                passportConfigLink: {
                    sectionId: 12,
                    indicatorId: 7,
                    subIndicatorId: 3,
                },
                type: 'BY_SUBINDICATOR_OPTIONS',
                parameters: [
                    {id: 1, name: "sec8-ind7-prm1", optionId: 1},
                    {id: 2, name: "sec8-ind7-prm2", optionId: 2},
                    {id: 3, name: "sec8-ind7-prm3", optionId: 3},
                    {id: 4, name: "sec8-ind7-prm4", optionId: 4},
                ]
            },
        ]
    },
    {
        id: 9,
        name: "sec9",
        title: "9. Индекс клиентоцентричности СЗН",
        indicators: [
            {
                id: 1,
                name: "sec9-ind1",
                type: 'RANGE-[)',
                title: "Индекс клиентоцентричности СЗН и ЦЗН: общий",
                passportConfigLink: {
                    sectionId: 8,
                    indicatorId: 1,
                    subIndicatorId: null,
                },
                parameters: [
                    {id: 1, name: "sec9-ind1-prm1", title: "до 20", criterion: [0, 20]},
                    {id: 2, name: "sec9-ind1-prm2", title: "от 20 до 30", criterion: [20, 30]},
                    {id: 3, name: "sec9-ind1-prm3", title: "от 30 до 40", criterion: [30, 40]},
                    {id: 4, name: "sec9-ind1-prm4", title: "от 40 до 50", criterion: [40, 100]},
                ]
            },
            {
                id: 2,
                name: "sec9-ind2",
                type: 'RANGE-[)',
                title: "Индекс клиентоцентричности СЗН и ЦЗН: показатели «Уровень дружелюбия и партнёрства»",
                passportConfigLink: {
                    sectionId: 8,
                    indicatorId: 1,
                    subIndicatorId: 1,
                },
                parameters: [
                    {id: 1, name: "sec9-ind2-prm1", title: "до 4", criterion: [0, 4]},
                    {id: 2, name: "sec9-ind2-prm2", title: "от 4 до 8", criterion: [4, 8]},
                    {id: 3, name: "sec9-ind2-prm3", title: "от 8 до 12", criterion: [8, 12]},
                    {id: 4, name: "sec9-ind2-prm4", title: "от 12 до 16", criterion: [12, 100]},
                ]
            },
            {
                id: 3,
                name: "sec9-ind3",
                type: 'RANGE-[)',
                title: "Индекс клиентоцентричности СЗН и ЦЗН: показатели «Уровень полезности обращения в СЗН»",
                passportConfigLink: {
                    sectionId: 8,
                    indicatorId: 1,
                    subIndicatorId: 2,
                },
                parameters: [
                    {id: 1, name: "sec9-ind3-prm1", title: "до 2", criterion: [0, 2]},
                    {id: 2, name: "sec9-ind3-prm2", title: "от 2 до 4", criterion: [2, 4]},
                    {id: 3, name: "sec9-ind3-prm3", title: "от 4 до 6", criterion: [4, 100]},
                ]
            },
            {
                id: 4,
                name: "sec9-ind4",
                type: 'RANGE-[)',
                title: "Индекс клиентоцентричности СЗН и ЦЗН: показатели «Уровень минимизации усилий клиента»",
                passportConfigLink: {
                    sectionId: 8,
                    indicatorId: 1,
                    subIndicatorId: 3,
                },
                parameters: [
                    {id: 1, name: "sec9-ind4-prm1", title: "до 4", criterion: [0, 4]},
                    {id: 2, name: "sec9-ind4-prm2", title: "от 4 до 8", criterion: [4, 8]},
                    {id: 3, name: "sec9-ind4-prm3", title: "от 8 до 12", criterion: [8, 12]},
                    {id: 4, name: "sec9-ind4-prm4", title: "от 12 до 16", criterion: [12, 100]},
                ]
            },
            {
                id: 5,
                name: "sec9-ind5",
                type: 'RANGE-[)',
                title: "Индекс клиентоцентричности СЗН и ЦЗН: показатели «Уровень персонализации и адресности»",
                passportConfigLink: {
                    sectionId: 8,
                    indicatorId: 1,
                    subIndicatorId: 4,
                },
                parameters: [
                    {id: 1, name: "sec9-ind5-prm1", title: "до 1", criterion: [0, 1]},
                    {id: 2, name: "sec9-ind5-prm2", title: "от 1 до 2", criterion: [1, 2]},
                    {id: 3, name: "sec9-ind5-prm3", title: "от 2 до 3", criterion: [2, 3]},
                    {id: 4, name: "sec9-ind5-prm4", title: "от 3 до 4", criterion: [3, 100]},
                ]
            },
            {
                id: 6,
                name: "sec9-ind6",
                type: 'RANGE-[)',
                title: "Индекс клиентоцентричности СЗН и ЦЗН: показатели «Уровень внешнего комфорта и удобства»",
                passportConfigLink: {
                    sectionId: 8,
                    indicatorId: 1,
                    subIndicatorId: 5,
                },
                parameters: [
                    {id: 1, name: "sec9-ind6-prm1", title: "до 2", criterion: [0, 2]},
                    {id: 2, name: "sec9-ind6-prm2", title: "от 2 до 4", criterion: [2, 4]},
                    {id: 3, name: "sec9-ind6-prm3", title: "от 4 до 6", criterion: [4, 6]},
                    {id: 4, name: "sec9-ind6-prm4", title: "от 6 до 8", criterion: [6, 100]},
                ]
            }
        ]
    }
]



